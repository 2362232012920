import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logoMin from '../img/logo_min.png'
import Img from 'gatsby-image'

const PostLink = ({ post }) => (
  <Wrapper>
    <Link to={post.frontmatter.path}>
      <div className="meta">
        <img src={logoMin} width={36} height={36} alt="avatar" />
        <div>
          <h6>{post.frontmatter.date}</h6>
          <h6>
            By {post.frontmatter.author}, {post.fields.readingTime.text}
          </h6>
        </div>
      </div>
      <h3>{post.frontmatter.title}</h3>
      {post.frontmatter.featuredImage && (
        <Img
          className="coverImage"
          sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
        />
      )}
      <p className="excerpt">{post.excerpt}</p>
      <span>Read more...</span>
    </Link>
  </Wrapper>
)

export default PostLink

const Wrapper = styled.div`
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid #ccc;

  h6 {
    color: #777 !important;
  }

  .coverImage {
    margin: 20px 0;
  }

  h3 {
    margin-top: 10px;
    font-size: 30px;
  }

  p {
    font-size: 18px;
  }
  .meta {
    font-size: 15px;
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
  }

  a:hover {
    color: #145a6f !important;
  }

  span {
    font-size: 15px;
  }
`
