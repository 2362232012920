import React from 'react'
import { graphql } from 'gatsby'
import PostLink from '../components/post-link'
import styled from 'styled-components'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import '../designs/dist/css/bootstrap.min.css'
import '../designs/dist/js/slick/slick.css'
import '../designs/dist/css/main.min.css'

class BlogPage extends React.Component {
  state = {
    menuVisible: false,
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    const posts = this.props.data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
      .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
    return (
      <>
        <Layout
          menuVisible={this.state.menuVisible}
          toggleMenu={this.toggleMenu}
        >
          <Wrapper>
            <div className="posts-container">
              <h2>Latest posts</h2>
              {posts}
            </div>
          </Wrapper>
        </Layout>
      </>
    )
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
  }).isRequired,
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 120)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
            cta
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 740) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
const Wrapper = styled.div`
  background-color: white;
  color: #145a6f;
  min-height: calc(100vh - 250px);
  padding-top: 70px;
  padding-bottom: 20px;
  margin: auto;

  .posts-container {
    margin: auto;
    width: 760px;
    padding: 20px;
    max-width: 100%;

    a {
      color: #145a6f;
    }
    a:hover {
      color: rgb(0, 182, 214);
    }
  }
`
